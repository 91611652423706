<!--
 * @Author: ttheitao
 * @Date: 2022-01-20 15:54:47
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-09-26 14:02:11
 * @Description: excel组件
-->
<template>
	<div :style="wrapStyle" v-loading="isLoading">
		<div
			style="width: 100%; height: 100%; display: flex; z-index: 0"
			v-if="this.excelData.version == 2 && dataDownloaded"
		>
			<div style="flex: 1">
				<bimcc-spreadsheet
					ref="bimccsheet"
					:workbookData="workbookData"
					:workbookState="workbookState"
					:disabledContextMenu="disabledContextMenu"
					:style="iframeStyle"
					:groupIdSuffix="this.solveType === 'table' ? 'list' : String(this.dataIds)"
					@spreadsheet-mounted="handleSpreadsheetMounted"
					@export-prepared="handleExportPrepared"
					@cell-render-before="cellRenderBefore"
				></bimcc-spreadsheet>
			</div>
			<!-- <div class="flow-box" :style="flowBoxStyle"> -->
			<div v-if="needFlow" class="flow-box">
				<div class="fb-main">
					<template v-if="type === 'add'">
						<FlowPicker
							type="self"
							:module="formUuid"
							:flow-edit="editFieldsValue"
							@checked-users="dealWithPicker"
							@selected-step="getSelectStep"
						/>
					</template>
					<template v-else-if="['edit', 'info'].includes(type)">
						<!--详情界面流程-->
						<div class="flow-recover-container" v-if="displayData.allLogs">
							<template v-for="(item, index) in displayData.allLogs">
								<FlowRecover
									:key="index"
									:canvas-data="item.recover"
									:logs="item.logs"
									:next-step="displayData.nextStep"
									:current-step="displayData.curStepNode"
									:flowShow="flowShow"
								/>
								<el-divider :key="index"></el-divider>
							</template>
						</div>
						<div class="flow-recover-container" v-else-if="displayData.recover">
							<FlowRecover
								:canvas-data="displayData.recover"
								:logs="displayData.logs"
								:next-step="displayData.nextStep"
								:current-step="displayData.curStepNode"
								:flowShow="flowShow"
							/>
						</div>
						<el-empty v-else description="暂无流程数据"></el-empty>
					</template>
				</div>
			</div>
		</div>
		<div v-if="this.excelData.version == 1" class="iframe-class" ref="iframeBox" element-loading-text="连接表格服务">
			<iframe
				class="create-iframe-class"
				ref="sssIframe"
				v-if="calcLoadType === 'create'"
				:src="sssDomain"
				@load="iframeLoaded"
				:style="iframeStyle"
			></iframe>
			<div class="btns" v-if="needBtn">
				<el-button @click="close" :disabled="isUpload">取消</el-button>
				<el-button type="primary" @click="submit" :disabled="isUpload">确认</el-button>
			</div>
			<el-drawer custom-class="flow-drawer" title="审批" :append-to-body="true" :visible.sync="showFlow" size="400px">
				<div v-if="needFlow" class="flow-box">
					<div class="fb-main">
						<template v-if="type === 'add'">
							<FlowPicker
								:module="formUuid"
								type="self"
								@checked-users="dealWithPicker"
								@selected-step="getSelectStep"
							/>
						</template>
						<template v-else-if="type === 'info'">
							<FlowRecover
								v-if="Object.keys(displayData).length"
								:canvas-data="displayData.recover"
								:logs="displayData.logs"
							/>
							<FlowOperate
								v-if="Object.keys(displayData).length"
								:edit-data="displayData"
								:flow-edit="editFieldsValue"
								:breakValid="false"
								@change-end="onRefresh"
							/>
						</template>
					</div>
					<div class="fb-footer">
						<el-button type="primary" @click="showFlow = false">确认</el-button>
					</div>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script>
import { dataInterface, transFieldsUUid } from '@/apis/data/index';
import FlowPicker from '@/custom-component/flow/flow-designer/src/operation/FlowPicker';
import FlowRecover from '@/custom-component/flow/flow-designer/src/operation/FlowRecover';
import FlowOperate from '@/custom-component/flow/flow-designer/src/operation/FlowOperate';
import { flowEngine } from '@/apis/data/workflow';
import { deepCopy } from '@/utils/tools';
import axios from 'axios';
import BimccSpreadsheet from '@/components/spreadsheet/BimccSpreadsheet.vue';
import { Empty, Divider } from 'element-ui';

// 支持的操作类型
let types = ['add', 'edit', 'info', 'export', 'bind'];

export default {
	name: 'Entry',
	components: {
		FlowPicker,
		FlowRecover,
		FlowOperate,
		'bimcc-spreadsheet': BimccSpreadsheet,
		'el-empty': Empty,
		'el-divider': Divider
	},
	props: {
		// 渲染类型 preload - 预加载（通过css绝对定位实现，目前仅支持弹窗），create - 新实例（每次调用都会重新请求外部资源）, local - 引入本地资源(未处理)
		loadType: { type: String, default: 'preload' },
		solveType: { type: String, default: 'form' },
		// 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
		type: {
			type: String,
			default: 'add',
			required: true,
			validator: function (value) {
				return types.indexOf(value) !== -1;
			}
		},
		// excel模板 uuid
		excelUuid: { type: String, default: '' },
		// 对象 uuid
		objectUuid: { type: String, default: '' },
		// 视图 uuid
		viewUuid: { type: String, default: '' },
		// 视图 search (导出列表时)
		viewSearch: {
			type: Array,
			default: function () {
				return [];
			}
		},
		// 视图 query（params）导出列表时
		viewQuery: {
			type: Object,
			default: function () {
				return {};
			}
		},
		// 表单 uuid（仅在新增时候使用）
		formUuid: { type: String, default: '' },
		// 数据 ids
		dataIds: { type: [Number, String, Array] },
		// 导出类型 [excel | image | pdf]
		exportType: { type: String, default: 'excel' },
		// 外部传入metadata
		excelMetaData: {
			type: [Object, null],
			required: false,
			default: null
		},
		// 外部传入表单设计数据
		formDesignData: {
			type: Object,
			required: false,
			default: null
		},
		// 外部传入表单配置
		excelFormData: {
			type: [Object, null],
			required: false,
			default: null
		},
		// 是否显示
		show: { type: Boolean, default: false },
		needFlow: { type: Boolean, default: false },
		displayData: {
			type: [Object, null],
			required: false,
			default: null
		},
		// 元素ID，用以postMessage回调时的标识认证
		elementId: { type: String, default: 'global' },
		// 是否需要操作按钮
		needBtn: { type: Boolean, default: false },
		// excel类型，1 - 数仓模板， 2 - 表单列表模板， 3 - 表单详情模板
		excelType: { type: Number, default: 1 },
		// 请求附加参数 <object>
		extraParams: { type: Object, default: () => {} },
		disabledContextMenu: {
			type: Boolean,
			default: false
		},
		// 动态全局锁定，为 true 时除流程允许操作的字段外，其他不允许操作
		globalLock: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			/* speradsheet 参数 */
			// 地址，此参数在预加载的情况下无效，以App.vue中为准
			sssDomain: 'https://sss.bimcc.com',
			// 当前excel是否为模版，设置为true则会在渲染完成后清空id
			isTemplate: false,
			// 是否显示工具栏
			showToolbar: false,
			/**
			 * 渲染模式， show - 预览，form - 表单， bind - 绑定
			 * 渲染模式在 showToolbar 为 true 的情况下可以在内部切换
			 * show模式不可以做任何编辑操作，且不显示表单控件
			 * form模式只可以对绑定有表单控件或表单收集配置的单元格操作
			 * bind模式仅在配置的时候使用
			 */
			renderMode: 'form',
			// 提交时是保存excel模板，为 false 时仅保存模版名称
			isSave: false,
			/**
			 * formdata:{
			 *      objectUuid1:{},
			 *      objectUuid2:[],
			 * }
			 * 数据键必须是uuid，为每条数据（包括子表）生成一个新的属性'__id__'，存放数据的id，如果无则为空
			 */
			formData: {},
			// 预设值
			presetValues: {},
			// 渲染完成后自动下载[excel | image | pdf]
			autoDownLoad: '',
			// 固化内容，渲染过程不再请求远程数据、不渲染传入的表单数据、预设值等
			fixed: false,
			/* 过程参数 */
			// iframe 加载状态
			isLoad: false,
			// 数据 加载状态
			dataDownloaded: false,
			dataMetadata: {},
			// 全局spreadsheet Iframe
			sssIframe: null,
			// 监听参数
			parentDom: null,
			observer: null,
			// loading
			isLoading: true,
			// 流程参数
			editFieldsValue: {},
			showFlow: false,
			flow_target_users: [],
			flow_send_users: [],
			flow_select_step: '',
			flow_picker_mum: 0,
			flow_engine_id: '',
			initCanvasData: null,
			flowShow: false,
			// sss 层级
			zIndex: 0,
			// 当前是否在保存excel模板
			isUpload: false,
			// 表格元数据
			excelData: {
				// 1 - lucky , 2 - bimcc-spreadsheet
				version: 0
			},
			// 新版表格需要的数据
			workbookData: {},
			workbookState: {},
			wrapStyle: {
				width: '0px',
				height: '0px',
				display: 'flex'
			},
			// excel文件数据
			excelFile: null
		};
	},
	created() {
		this.updateSize();
		// this.getData();

		if (this.elementId === 'global') {
			this.isLoad = true;
		}
		if (this.type == 'add') {
			flowEngine.designShow({ module: this.formUuid }).then((res) => {
				this.flow_engine_id = res.data.data.id;
			});
		}

		window.addEventListener('resize', () => {
			this.updateSize();
		});
	},
	computed: {
		iframeStyle() {
			if (this.type === 'export') {
				return {
					height: '0px',
					width: '0px',
					position: 'absolute',
					zIndex: -1
				};
			}
			return {};
		},
		calcLoadType() {
			// 当加载模式为 preload 但预加载资源未加载完成时强制改为 create 模式
			if (this.loadType === 'preload' && !window.sssLoaded) {
				return 'create';
			} else {
				return this.loadType;
			}
		},
		flowBoxStyle() {
			return {
				width: `${this.needFlow ? 360 : 0}px`,
				padding: `${this.needFlow ? 10 : 0}px`,
				marginLeft: `${this.needFlow ? 10 : 0}px`,
				border: this.needFlow ? '1px solid #ebebeb' : 'none'
			};
		}
	},
	watch: {
		show(newValue) {
			if (this.calcLoadType == 'preload') {
				if (newValue && this.type != 'export') {
					this.showSssIframe();
				} else {
					this.hideSssIframe();
				}
			}
		},
		excelFormData: {
			handler() {
				// console.log('entry excelFormData');
				this.$nextTick(() => {
					this.dataDownloaded = false;
					// this.solveType();
					this.getData();
				});
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		window.addEventListener('message', this.iframePostMessage);

		if (this.calcLoadType == 'preload') {
			window.onresize = () => {
				this.updateSize();
			};
		} else {
			this.sssIframe = this.$refs.sssIframe ?? null;
		}

		if (this.elementId === 'global' || this.type === 'bind') {
			// this.solveType();
		}

		if (this.type != 'export' && this.calcLoadType == 'preload') {
			this.parentDom = this.$parent.$el.getElementsByClassName('dialog-main')[0];
			this.zIndex = parseInt(this.$parent.$el.style.zIndex) + 1;
			this.updateSize();
		}

		// 监听父组件的属性变化更新宽高
		let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		this.observer = new MutationObserver(() => {
			this.updateSize();
		});
		this.observer.observe(this.$parent.$el, { attributes: true, childList: false, characterData: false });
	},
	methods: {
		getData() {
			if (this.excelUuid) {
				let params = {
					object_uuid: 'object617fbedcefab4',
					view_uuid: 'view64a3dc2808097',
					__method_name__: 'dataList',
					transcode: 0,
					search: [
						{
							code: `${isNaN(this.excelUuid) ? 'uuid' : 'id'}`,
							ruleType: 'eq',
							value: this.excelUuid
						}
					]
				};

				dataInterface(params).then((res9) => {
					this.resolveType();
					const excelData = res9.data.data.data[0];
					if (excelData.version === 1) {
						this.sssIframe =
							this.calcLoadType === 'create' ? this.$refs.sssIframe : document.getElementById('sssIframe');
						this.isLoading = false;
						this.excelData = excelData;
						this.updateSize();
						this.start();
						return;
					}

					axios.get(excelData.file).then((res) => {
						this.excelFile = res.data;
						this.workbookData = this.excelFile.data;
						this.workbookData.name = excelData.name;
						this.solveSheetData(excelData);
						this.isLoading = false;
						this.$nextTick(() => {
							this.excelData = excelData;
							this.start();
						});
					});
				});
			} else {
				this.isLoading = false;
			}
		},
		solveSheetData(excelData) {
			const formData = JSON.parse(JSON.stringify(this.formData));
			for (let uuid in formData) {
				if (formData[uuid].editFields && Object.keys(formData[uuid].editFields).length) {
					formData[uuid].metaEditFields = {};
					if (!this.formDesignData?.fields) {
						continue;
					}
					for (let i in this.formDesignData.fields) {
						const field = this.formDesignData.fields[i];
						if (formData[uuid].editFields[field.__config__.formId]) {
							formData[uuid].metaEditFields[field.__vModel__] = formData[uuid].editFields[field.__config__.formId];
						}
					}
				}
			}

			this.workbookState = {
				graphData: this.excelFile.graphData,
				...(excelData.config ?? {}),
				formData
			};

			// 强制运行图
			if (!this.workbookState?.nodeGraphConfig) {
				this.workbookState.nodeGraphConfig = {};
			}
			this.workbookState.nodeGraphConfig.isStart = true;
			this.workbookState.nodeGraphConfig.stepTime = 0;
			this.workbookState.nodeGraphConfig.needDOM = false;
			this.workbookState.nodeGraphConfig.isRefresh = this.type === 'export' ? false : true;
			this.workbookState.nodeGraphConfig.solveType = this.solveType;
		},
		// 解析操作类型
		resolveType() {
			switch (this.type) {
				case 'add':
					this.dataDownloaded = true;
					this.start();
					break;
				case 'edit':
					if (this.solveType === 'table') {
						this.getExportInfo();
					} else {
						this.getDataInfo();
					}
					break;
				case 'info':
					this.renderMode = 'show';
					if (this.solveType === 'table') {
						this.getExportInfo();
					} else {
						this.getDataInfo();
					}
					break;
				case 'export':
					this.renderMode = 'show';
					this.autoDownLoad = this.exportType;
					this.getExportInfo();
					break;
				case 'bind':
					this.showToolbar = true;
					this.renderMode = 'bind';
					this.isSave = true;
					this.dataDownloaded = true;
					this.start();
					break;
			}
		},
		// 获取表单数据详情
		getDataInfo() {
			if (this.excelMetaData && this.excelFormData) {
				this.dataDownloaded = true;
				this.formData = this.excelFormData;
				this.dataMetadata = this.excelMetaData;
				this.solveFormData(this.formData);

				setTimeout(() => {
					this.start();
				}, 0);
				return;
			}
			transFieldsUUid(this.objectUuid, this.formUuid)
				.then((res) => {
					if (res.data.code == 200) {
						// 获取已经有的数据进行绑定
						let config = res.data.data;
						let ruuids = [];
						for (let i = 0; i < config.fields.length; i++) {
							let item = config.fields[i];
							if (item.__config__.tagIcon == 'form') {
								ruuids.push({ relationship_uuid: item.__vModel__ });
							}
						}
						dataInterface({
							__method_name__: 'dataInfo',
							data_id: this.dataIds,
							object_uuid: this.objectUuid,
							ruuids,
							view_uuid: this.viewUuid,
							allMetadata: 1
						}).then((res) => {
							if (res.data.code == 200) {
								this.dataDownloaded = true;
								this.formData = {
									[this.objectUuid]: res.data.data
								};
								this.dataMetadata = res.data.metadata;
								this.solveFormData(this.formData);
								setTimeout(() => {
									this.start();
								}, 0);
							}
						});
					}
				})
				.catch((err) => {
					console.log(err, 'sss错误');
				});
		},
		getExportInfo() {
			if (this.solveType === 'form' && this.excelMetaData && this.excelFormData) {
				this.formData = this.excelFormData;
				this.dataMetadata = this.excelMetaData;
				this.solveFormData(this.formData);
				this.workbookState.formData = this.formData;
				this.dataDownloaded = true;
				setTimeout(() => {
					this.start();
				}, 0);
				return;
			} else if (this.solveType === 'table' && this.excelFormData) {
				this.formData = this.excelFormData;
				this.dataMetadata = this.excelMetaData;
				this.solveFormData(this.formData);
				this.workbookState.formData = this.formData;

				this.dataDownloaded = true;
				setTimeout(() => {
					this.start();
				}, 0);
				return;
			}

			if (this.dataIds == 'all' || Array.isArray(this.dataIds) || this.solveType === 'table') {
				let search = deepCopy(this.viewSearch);
				if (Array.isArray(this.dataIds)) {
					search.push({
						code: 'id',
						ruleType: 'in',
						value: this.dataIds
					});
				}

				if (this.objectUuid.startsWith('/graph/')) {
					const graphId = this.objectUuid.replace('/graph/', '');
					dataInterface(
						{
							search: search,
							...this.viewQuery,
							...this.extraParams,
							page: 1,
							size: 10000
						},
						`api/graph/${graphId}`
					).then((res) => {
						console.log(res, 'graph data');
						if (res.data.code == 200) {
							this.dataMetadata = res.data.metadata;

							this.formData = {
								[graphId]: res.data.data
							};
							if (this.checkPaginate(res.data.data)) {
								this.formData = {
									[graphId]: res.data.data.data
								};
							}
							this.solveFormData(this.formData);
							this.workbookState.formData = this.formData;
							this.dataDownloaded = true;
							setTimeout(() => {
								this.start();
							}, 0);
						}
					});
				} else {
					dataInterface({
						__method_name__: 'dataList',
						object_uuid: this.objectUuid,
						view_uuid: this.viewUuid,
						page: 1,
						size: 10000,
						search: search,
						...this.viewQuery,
						...this.extraParams
					}).then((res) => {
						if (res.data.code == 200) {
							this.dataMetadata = res.data.metadata;

							this.formData = {
								[this.objectUuid]: res.data.data
							};
							if (this.checkPaginate(res.data.data)) {
								this.formData = {
									[this.objectUuid]: res.data.data.data
								};
							}
							this.solveFormData(this.formData);
							this.workbookState.formData = this.formData;
							this.dataDownloaded = true;

							setTimeout(() => {
								this.start();
							}, 0);
						}
					});
				}
			} else {
				if (this.objectUuid.startsWith('/graph/')) {
					const graphId = this.objectUuid.replace('/graph/', '');
					dataInterface({...this.extraParams}, `api/graph/${graphId}`).then((res) => {
						if (res.data.code == 200) {
							this.formData = {
								[graphId]: res.data.data
							};

							this.workbookState.formData = this.formData;
							this.dataDownloaded = true;
							setTimeout(() => {
								this.start();
							}, 0);
						}
					});
				} else {
					dataInterface({
						__method_name__: 'dataInfo',
						data_id: this.dataIds,
						object_uuid: this.objectUuid,
						view_uuid: this.viewUuid,
						allMetadata: 1
					}).then((res) => {
						if (res.data.code == 200) {
							this.formData = {
								[this.objectUuid]: res.data.data
							};

							this.workbookState.formData = this.formData;
							this.dataDownloaded = true;
							setTimeout(() => {
								this.start();
							}, 0);
						}
					});
				}
			}
		},
		// 检查是否包含分页
		checkPaginate(data) {
			if (Reflect.has(data, 'current_page') || Reflect.has(data, 'page')) {
				return true;
			}
			return false;
		},
		// iframe 加载完成
		iframeLoaded() {
			this.sssIframe = this.calcLoadType === 'create' ? this.$refs.sssIframe : document.getElementById('sssIframe');
			this.isLoad = true;
			this.start();
		},
		// 开始加载
		start() {
			// 当所有异步加载完成才渲染表格
			// console.log('isLoad '+this.isLoad,' dataDownloaded ' + this.dataDownloaded);
			if (this.isLoad && this.dataDownloaded) {
				this.handleIframe();
			}
		},
		// 处理传入的数据，附加 __id__ 标识
		solveFormData(data) {
			for (let key in data) {
				if (Reflect.has(this.dataMetadata, key) && this.dataMetadata[key] == 'id') {
					data.__id__ = data[key];
				} else if (Object.prototype.toString.call(data[key]) === '[object Object]' || Array.isArray(data[key])) {
					this.solveFormData(data[key]);
				}
			}
		},
		// 发送数据到表格
		handleIframe() {
			if (this.sssIframe) {
				let archiInfo = this.$GetTargetArchi();
				// console.log('entry handleIframe',this.elementId);
				this.sssIframe.contentWindow.postMessage(
					{
						event: 'spreadsheetInit',
						options: {
							baseUrl: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'https://dwh_api.bimcc.net/',
							metadataToken: process.env.VUE_APP_METATOKEN,
							bimcctoken: this.$GetToken(),
							id: this.excelUuid,
							isTemplate: this.isTemplate,
							renderMode: this.renderMode,
							showToolbar: this.showToolbar,
							isSave: this.isSave,
							formData: JSON.parse(JSON.stringify(this.formData)),
							presetValues: JSON.parse(JSON.stringify(this.presetValues)),
							autoDownLoad: this.autoDownLoad,
							fixed: this.fixed,
							extra: {
								archiId: archiInfo.archiId,
								archiType: archiInfo.archiType,
								entryType: this.type,
								loadType: this.calcLoadType,
								elementId: this.elementId,
								ownerUuid: this.formUuid,
								excelType: this.excelType,
								objectUuid: this.objectUuid
							}
						}
					},
					'*'
				);
			}
		},
		// 监听表格事件
		iframePostMessage(e) {
			// console.log(e.data, 'sss回调');
			// 内部提交
			// if (e.data.event == 'spreadsheetSubmit') { }

			// sss开始实例化事件
			// console.log('event id:'+ e.data.elementId ,'-------------------','oid:'+ this.elementId);
			// 多个实例下，postMessage事件会全部广播，要先匹配元素ID，预加载的iframe ID为 global
			if (e.data.elementId !== this.elementId) {
				return;
			}

			// 上传完成
			if (e.data.event === 'spreadsheetUploaded') {
				this.$emit('sss-uploaded');
				this.isUpload = false;
				this.close();
			}

			if (e.data.event === 'spreadsheetRun') {
				if (this.calcLoadType == 'preload') {
					if (this.type != 'export' && this.show) {
						this.isLoading = false;
						this.showSssIframe();
					}
				} else if (this.calcLoadType == 'create') {
					this.isLoading = false;
				}
			}

			// 打开流程审批
			if (e.data.event === 'spreadsheetOpenFlow') {
				this.showFlow = true;
			}

			// 导出完成
			if (e.data.event === 'spreadsheetAdSucceed') {
				this.$emit('exportSuccess', {
					url: e.data?.url ? e.data.url : '',
					objects: e.data?.objects ? e.data.objects : {}
				});
			}

			// 导出错误
			if (e.data.event === 'spreadsheetError') {
				console.log(e.data.message, '-----');
				this.$emit('exportError', e.data.message);
			}

			// 表单数据响应事件
			if (e.data.event == 'spreadsheetResponseFormData') {
				let data = {};
				if (this.solveType === 'form') {
					data = e.data.formData[this.objectUuid];
				} else if (this.solveType === 'table') {
					data.data = e.data.formData;
				}

				data.target_users = this.flow_target_users;
				data.send_users = this.flow_send_users;
				data.select_step = this.flow_select_step;
				if (e.data.validate) {
					this.getSelfPicker({ module: this.formUuid }).then((res) => {
						if (res.data.code == 200) {
							let curflowUsers = res.data.data;
							let needTargetUseres = false;
							if (curflowUsers.target_picker.length) {
								needTargetUseres = true;
							}

							// 提交事件
							if (
								this.needFlow &&
								needTargetUseres &&
								(this.flow_picker_mum === 0 || this.flow_picker_mum !== this.flow_target_users.length)
							) {
								this.showFlow = true;
								return this.$message.error('审批人未选择完整！');
							}

							if (this.type == 'add' && (this.flow_engine_id == '' || typeof this.flow_engine_id === 'undefined')) {
								return this.$message.error('未获取到流程引擎！');
							} else {
								data.flow_engine_id = this.flow_engine_id;
							}

							// 过滤流程id，否则流程会失效
							if (this.type === 'edit' && Object.hasOwn(data, 'flow_engine_id')) {
								delete data.flow_engine_id;
							}

							console.log('spreadsheet submit', data);
							this.$emit('submit', data);
						}
					});
				} else {
					// 过滤流程id，否则流程会失效
					if (this.type === 'edit' && Object.hasOwn(data, 'flow_engine_id')) {
						delete data.flow_engine_id;
					}

					// 接收数据事件
					console.log('spreadsheet receive', data);
					this.$emit('receive', data);
				}
			}
		},
		// 触发表格内表单数据提交（后续可能有验证逻辑）
		triggerSubmit() {
			if (this.sssIframe) {
				this.sssIframe.contentWindow.postMessage(
					{ event: 'spreadsheetRequestFormData', validate: true, elementId: this.elementId },
					'*'
				);
			} else {
				// sssIframe 不存在则表示使用的自研版Excel
				let data = {};
				const excelFormData = this.$refs.bimccsheet.getFormData();
				const objcetData = Array.isArray(excelFormData[this.objectUuid])
					? excelFormData[this.objectUuid][0]
					: excelFormData[this.objectUuid];
				if (this.solveType === 'form') {
					data = objcetData ?? {};
					data.target_users = this.flow_target_users;
					data.send_users = this.flow_send_users;
					data.select_step = this.flow_select_step;
					data.new_flow = true;

					this.getSelfPicker({ module: this.formUuid }).then((res) => {
						if (res.data.code == 200) {
							let curflowUsers = res.data.data;
							let needTargetUseres = false;
							if (curflowUsers.target_picker.length) {
								needTargetUseres = true;
							}

							// 提交事件
							if (
								this.needFlow &&
								needTargetUseres &&
								(this.flow_picker_mum === 0 || this.flow_picker_mum !== this.flow_target_users.length)
							) {
								this.showFlow = true;
								return this.$message.error('审批人未选择完整！');
							}

							if (this.type == 'add' && (this.flow_engine_id == '' || typeof this.flow_engine_id === 'undefined')) {
								return this.$message.error('未获取到流程引擎！');
							} else {
								data.flow_engine_id = this.flow_engine_id;
							}

							// 过滤流程id，否则流程会失效
							if (this.type === 'edit' && Object.hasOwn(data, 'flow_engine_id')) {
								delete data.flow_engine_id;
							}

							this.$emit('submit', data);
						}
					});
				} else {
					this.$emit('submit', { data: excelFormData });
				}
			}
		},
		// 获取当前表单数据（无验证逻辑）,如使用的是bimmc-spreadsheet版本，会直接返回数据
		getFormData() {
			if (this.sssIframe) {
				this.sssIframe.contentWindow.postMessage(
					{ event: 'spreadsheetRequestFormData', validate: false, elementId: this.elementId },
					'*'
				);
			} else {
				const excelFormData = this.$refs.bimccsheet.getFormData();
				const res = excelFormData?.[this.objectUuid] ?? {};
				// 过滤流程id，否则流程会失效
				if (this.type === 'edit' && Object.hasOwn(res, 'flow_engine_id')) {
					delete res.flow_engine_id;
				}

				this.$emit('receive', res);
				return res;
			}
		},
		// 触发保存Excel模板
		triggerUpload() {
			if (!this.isUpload) {
				this.isUpload = true;
				if (this.sssIframe) {
					this.sssIframe.contentWindow.postMessage(
						{
							event: 'spreadsheetTriggerUpload'
						},
						'*'
					);
				}
			}
		},
		getSelfPicker(params) {
			return flowEngine.selfPicker(params);
		},
		// 更新宽高
		updateSize() {
			// if (!this.parentDom) {
			//     const dom = this.$parent.$el.getElementsByClassName('dialog-main')[0];
			//     if (dom) {
			//         this.wrapStyle.width = dom.offsetWidth - 2 + 'px';
			//         this.wrapStyle.height = dom.offsetHeight - 2 + 'px';
			//     } else {
			//         this.wrapStyle.width = '100%';
			//         this.wrapStyle.height = '100%';
			//     }
			// } else {
			//     this.wrapStyle.width = '100%';
			//     this.wrapStyle.height = '100%';
			// }
			if (this.type != 'export') {
				if (this.sssIframe) {
					if (this.parentDom) {
						this.sssIframe.style.width = this.parentDom.offsetWidth + 'px';
						this.sssIframe.style.height = this.parentDom.offsetHeight + 'px';
					} else {
						this.sssIframe.style.width = '100%';
						this.sssIframe.style.height = '100%';
					}
				} else {
					this.wrapStyle.width = '100%';
					this.wrapStyle.height = '100%';
					this.wrapStyle.padding = '10px';
					this.wrapStyle.boxSizing = 'border-box';
				}
			}

			if (this.$refs['bimccsheet']) {
				this.$refs['bimccsheet'].resize();
			}
		},
		showSssIframe() {
			if (this.calcLoadType == 'preload') {
				if (this.sssIframe) {
					this.sssIframe.style.top = '78px';
					this.sssIframe.style.right = '16px';
					this.sssIframe.style.zIndex = this.zIndex;
				}
			}
		},
		hideSssIframe() {
			if (this.calcLoadType == 'preload') {
				if (this.sssIframe) {
					this.sssIframe.style.top = '10000px';
					this.sssIframe.style.right = '10000px';
					this.sssIframe.style.zIndex = -1;
				}
			}
		},
		// 流程人员选择事件
		// dealWithPicker(selected, total)
		dealWithPicker(selected) {
			// selected 加入formdata里 target_users / send_users
			this.flow_send_users = selected.send_users;
			this.flow_target_users = selected.target_users;
			this.flow_picker_mum = selected.target_users.length ? selected.target_users[0].nodeUser.length : 0;
		},
		// 流程步骤
		getSelectStep(nodeId) {
			// nodeId 加入formdata里 ，键名 select_step
			this.flow_select_step = nodeId;
		},
		onRefresh() {
			// 刷新
			this.showFlow = false;
		},
		// 重载
		reload() {
			this.solveSheetData(this.excelData);

			if (this.excelData.version === 1) {
				this.resolveType();
			} else {
				this.$refs.bimccsheet.reload();
			}
		},
		// 刷新
		refresh() {
			if (this.excelData.version === 1) {
				this.resolveType();
			} else {
				this.$refs.bimccsheet.refresh();
			}
		},
		close() {
			this.$emit('close');
		},
		submit() {
			this.triggerUpload();
		},
		handleSpreadsheetMounted() {},
		handleExportPrepared() {
			if (this.type === 'export') {
				switch (this.exportType) {
					case 'excel':
						this.$refs.bimccsheet.exportExcel();
						break;
					case 'pdf':
						this.$refs.bimccsheet.exportPDF();
						break;
					default:
						this.$refs.bimccsheet.exportExcel();
				}
				// 导出完成
				this.$emit('exportSuccess', { url: '', objects: null });
			}
		},
		cellRenderBefore(data) {
			if ((this.type === 'info' && this.needFlow) || this.globalLock) {
				const lo = data.event.view.cellCache.__lo__ ?? data.event.view.style?.lo ?? 1;
				data.event.view.cellCache.__lo__ = lo;
			}
		}
	},
	beforeDestroy() {
		if (this.calcLoadType == 'preload') {
			if (this.sssIframe) {
				this.sssIframe.contentWindow.postMessage(
					{
						event: 'spreadsheetHide'
					},
					'*'
				);

				if (this.type != 'export') {
					this.observer.disconnect();
				}
			}
		}

		window.removeEventListener('message', this.iframePostMessage);

		this.hideSssIframe();
	}
};
</script>

<style lang="less" scoped>
.iframe-class {
	width: 100%;
	height: 100%;
	border: none;
	display: flex;
	flex-direction: column;

	.btns {
		border-top: 1px solid #f2f3f5;
		margin-top: 10px;
		padding: 10px 0;
		text-align: right;
	}
}

.create-iframe-class {
	width: 100%;
	flex: 1;
	border: none;
}

.flow-box {
	width: 360px;
	height: 100%;
	padding: 10px;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow-y: auto;
	border: 1px solid #ebebeb;
	transition: width 500ms;

	.fb-main {
		flex: 1;
	}

	.fb-footer {
		text-align: right;
	}
}
</style>
