var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('div',{staticClass:"global-dialog global-dialog-one"},[_c('el-dialog',{attrs:{"title":_vm.title,"custom-class":"global-dialog-overscroll","visible":_vm.showDialog,"before-close":_vm.onClose,"width":_vm.width || '60%',"fullscreen":_vm.isFullScreen},on:{"update:visible":function($event){_vm.showDialog=$event}}},[_c('header',{staticClass:"global-dialog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',[_vm._v(_vm._s(_vm.title || '--'))]),_c('i',{staticClass:"iconfont fullscreen",class:!_vm.isFullScreen ? 'iconquanping6' : 'icontuichuquanping1',on:{"click":_vm.onToggleScreen}})]),(!_vm.prepared)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticStyle:{"height":"500px"}}):(_vm.newFormFlow)?_c('PcParserNew',{ref:"dialogForm",attrs:{"form-data":_vm.formDesignData,"displayData":_vm.displayData,"editFields":_vm.editFields,"disabled":_vm.disabled,"hideBtn":_vm.hide_btn,"needFlow":!!_vm.needFlow,"moduleUuid":_vm.formUUID,"needParentFlow":!!_vm.needParentFlow,"formMode":_vm.requestType,"nowArchiType":_vm.archi_type},on:{"submit":_vm.onFormSubmit,"on-close":_vm.onEndFlow,"edit-change":_vm.formEditChange}}):_c('PcParser',{ref:"dialogForm",class:{ p20: !_vm.newFormFlow },attrs:{"form-data":_vm.formDesignData,"displayData":_vm.displayData,"disabled":_vm.disabled,"editFields":_vm.editFields,"hideBtn":_vm.hide_btn,"needFlow":!!_vm.needFlow,"moduleUuid":_vm.formUUID,"needParentFlow":!!_vm.needParentFlow,"formMode":_vm.requestType,"nowArchiType":_vm.archi_type},on:{"on-close":_vm.onEndFlow,"submit":_vm.onFormSubmit}}),(
        _vm.newFormFlow &&
        ![
          'BIM模型管理',
          '计量报审',
          '人员计划',
          '年度成本预算',
          '总成本预算',
        ].includes(_vm.title)
      )?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[(
          Object.keys(_vm.displayData).length &&
          _vm.displayData.btn &&
          _vm.displayData.btn.length
        )?_c('FlowOperate',{attrs:{"edit-data":_vm.displayData,"flow-edit":_vm.editFieldsValue,"open-next-flow":!_vm.deal_object_uuid,"isWarn":_vm.deal_object_uuid !== ''},on:{"change-end":_vm.onEndFlow}}):_vm._e()],1):_vm._e(),(
        _vm.show_footer &&
        !(
          Object.keys(_vm.displayData).length &&
          _vm.displayData.btn &&
          _vm.displayData.btn.length
        )
      )?_c('div',{staticClass:"custom-btns",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"loading":_vm.isReadLoading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.is_read ? '设为已读' : '确认'))])],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }