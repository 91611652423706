<!--
 * @Description: 3.0消息弹窗
 * @Author: luocheng
 * @Date: 2021-12-20 19:09:02
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-28 09:47:01
 * @FilePath: \dataview-viewer-test\src\components\global\Dailog.vue
-->
<template>
  <div class="global-dialog global-dialog-one" v-if="showDialog">
    <el-dialog
      :title="title"
      custom-class="global-dialog-overscroll"
      :visible.sync="showDialog"
      :before-close="onClose"
      :width="width || '60%'"
      :fullscreen="isFullScreen"
    >
      <header class="global-dialog-title" slot="title">
        <h3>{{ title || '--' }}</h3>
        <i
          class="iconfont fullscreen"
          :class="!isFullScreen ? 'iconquanping6' : 'icontuichuquanping1'"
          @click="onToggleScreen"
        ></i>
      </header>
      <div v-if="!prepared" v-loading="true" style="height: 500px"></div>
      <PcParserNew
        v-else-if="newFormFlow"
        ref="dialogForm"
        :form-data="formDesignData"
        :displayData="displayData"
        :editFields="editFields"
        :disabled="disabled"
        :hideBtn="hide_btn"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        @submit="onFormSubmit"
        @on-close="onEndFlow"
        @edit-change="formEditChange"
      >
      </PcParserNew>
      <PcParser
        v-else
        ref="dialogForm"
        :class="{ p20: !newFormFlow }"
        :form-data="formDesignData"
        :displayData="displayData"
        :disabled="disabled"
        :editFields="editFields"
        :hideBtn="hide_btn"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        @on-close="onEndFlow"
        @submit="onFormSubmit"
      >
      </PcParser>
      <div
        slot="footer"
        v-if="
          newFormFlow &&
          ![
            'BIM模型管理',
            '计量报审',
            '人员计划',
            '年度成本预算',
            '总成本预算',
          ].includes(title)
        "
      >
        <FlowOperate
          v-if="
            Object.keys(displayData).length &&
            displayData.btn &&
            displayData.btn.length
          "
          :edit-data="displayData"
          :flow-edit="editFieldsValue"
          @change-end="onEndFlow"
          :open-next-flow="!deal_object_uuid"
          :isWarn="deal_object_uuid !== ''"
        />
      </div>
      <div
        slot="footer"
        class="custom-btns"
        v-if="
          show_footer &&
          !(
            Object.keys(displayData).length &&
            displayData.btn &&
            displayData.btn.length
          )
        "
      >
        <el-button @click="onClose">取消</el-button>
        <el-button
          :loading="isReadLoading"
          type="primary"
          @click="handleSubmit"
        >
          {{ is_read ? '设为已读' : '确认' }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PcParser from '@/custom-component/form/newParser/PcParser';
import PcParserNew from '@/custom-component/form/newParser/PcParserNew.vue';
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import FlowOperate from '@/custom-component/flow/flow-designer/src/operation/FlowOperate';
import { getTODORead } from '@/apis/home.js';

export default {
  name: 'GlobalDialog',
  components: {
    PcParser,
    PcParserNew,
    FlowOperate,
  },
  props: {
    form_uuid: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    data_id: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: '提示',
    },
    tag: {
      type: String,
      default: '',
    },
    archi_type: {
      type: String,
      default: '',
    },
    show_footer: {
      type: Boolean,
      default: false,
    },
    use_object_uuid: {
      type: String,
      default: '',
    },
    is_read: {
      type: Boolean,
      default: false,
    },
    msg_id: {
      type: Number,
      default: 0,
    },
    hide_btn: {
      type: Boolean,
      default: true,
    },
    deal_object_uuid: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '60%',
    },
  },
  data() {
    return {
      showDialog: false,
      displayData: {},
      editFields: {},
      formDisabled: 'dataInfo',
      formDesignData: {},
      // 流程
      needFlow: true,
      needParentFlow: false,
      formUUID: '',
      requestType: 'detail',
      loading: true,
      prepared: false,
      // 全屏
      isFullScreen: false,
      newFormFlow: false, // 新流程表单
      editFieldsValue: {},
      isReadLoading: false,
    };
  },
  mounted() {
    this.formUUID = this.form_uuid;
    this.showDialog = true;
    this.loading = true;
    // TIPS 请求表单携带原始数据id @凌志华
    transFieldsUUid(
      '',
      this.formUUID,
      this.use_object_uuid || this.deal_object_uuid
        ? { EDITOR_closeConfigCache: false }
        : {
            tag: this.tag,
            org_data_id: this.data_id,
          }
    )
      .then((res) => {
        if (res.data.code == 200) {
          this.needFlow = res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == 'form') {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          if (
            (this.use_object_uuid && !this.data_id) ||
            (this.deal_object_uuid && !this.data_id)
          ) {
            this.formDesignData = res.data.data;
            this.displayData = {};
            this.loading = false;
            this.prepared = true;
            this.newFormFlow = true;
            this.requestType = 'add';
            return;
          }
          dataInterface({
            data_id: this.data_id,
            object_uuid: this.use_object_uuid || this.formUUID,
            view_uuid: '',
            ruuids,
            __method_name__: 'dataInfo',
            __now_archi_type: this.archi_type,
            tag: this.tag,
          }).then((res1) => {
            let data = res1.data.data;
            this.formDesignData = res.data.data;
            if (data?.recover?.cells) {
              this.newFormFlow = true; // 新版流程表单
            }
            if (this.data_id) {
              this.displayData = data;
            } else {
              //新增
              this.formDesignData = res.data.data;
              this.requestType = 'add';
              this.newFormFlow = true;
              this.displayData = {};
              for (let key in data) {
                this.$set(this.displayData, key, null);
              }
            }
            this.editFields = data?.editFields || {};
            this.loading = false;
            this.prepared = true;
            // this.showDialog = true;
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  },
  methods: {
    formEditChange(val) {
      this.editFieldsValue = val;
    },
    onClose() {
      this.showDialog = false;
      this.loading = false;
      this.$emit('close');
    },
    handleSubmit() {
      if (this.is_read) {
        this.isReadLoading = true;
        getTODORead({ msg_id: this.msg_id })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success('操作成功');
              this.onClose();
              window.parent.postMessage({ event: 'refresh-todo-list' }, '*');
            }
          })
          .finally(() => {
            this.isReadLoading = false;
          });
        return;
      }
      this.$refs.dialogForm.handleSubmit();
    },
    /**
     * @desc: 表单提交
     */
    onFormSubmit(data) {
      if (this.use_object_uuid) {
        this.loading = true;
        dataInterface({
          ...data,
          object_uuid: this.use_object_uuid,
          __method_name__: 'createData',
        })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success('保存成功');
              window.parent.postMessage({ event: 'refresh-todo-list' }, '*');
            }
          })
          .finally(() => {
            this.onClose();
          });
      } else if (this.deal_object_uuid) {
        //预警处理
        let param = {
          ...data,
          object_uuid: this.deal_object_uuid,
          __method_name__: this.data_id ? 'updateData' : 'createData',
        };
        if (this.data_id) {
          param.data_id = this.data_id;
        }
        dataInterface(param)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success('保存成功');
              eventBus.$emit('handle-todo-warn-status');
            }
          })
          .finally(() => {
            this.onClose();
          });
      }
    },
    /**
     * @desc: 操作完毕关闭弹窗
     */
    onEndFlow() {
      this.onClose();
      // 更新状态列表
      if (this.use_object_uuid) {
        //首页待办预警不需要这步操作
        eventBus.$emit('updateTodoList');
      }
    },
    /**
     * @description: 切换全屏/非全屏
     */
    onToggleScreen() {
      this.isFullScreen = !this.isFullScreen;
      // document.querySelector('.global-dialog-overscroll').style.height = '100vh';
      document.querySelector('.global-dialog-overscroll').style.maxHeight = this
        .isFullScreen
        ? '100vh'
        : '80%';
    },
  },
};
</script>

<style lang="less">
.global-dialog-overscroll {
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;
  box-sizing: border-box;
  padding: 5px;
  transition: all 0.2s;
  .el-dialog__header {
    border-bottom: 1px solid #f2f3f5;
    padding-top: 10px;
    .el-dialog__headerbtn {
      top: 15px;
    }
    .el-icon-close {
      position: relative;
      line-height: 22px;
      font-size: 20px;
      color: #4d535e;
    }
  }
  .global-dialog-title {
    display: flex;
    h3 {
      flex: 1;
      line-height: 22px;
      font-size: 18px;
      color: #303133;
      box-sizing: border-box;
      padding-right: 20px;
    }
    .fullscreen {
      margin-right: 30px;
      // color: #909399;
      color: #4d535e;
      line-height: 22px;
      font-size: 22px;
    }
  }
  .el-dialog__body {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 0;
  }
  .el-dialog__footer {
    padding: 10px 0;
    .custom-btns {
      padding: 0 15px;
    }
  }
  .p20 {
    padding: 20px;
  }
  .el-button--primary {
    background: var(--themeColor);
    border-color: var(--themeColor);
    &:hover {
      background: var(--themeColor);
      border-color: var(--themeColor);
    }
  }
  .el-button--default {
    &:hover {
      color: var(--themeColor);
      border-color: var(--el-color-primary-light-7);
      background-color: var(--el-color-primary-light-9);
    }
  }
}
</style>
